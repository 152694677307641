
import {
  alertController,
  IonPage,
  IonContent,
  loadingController,
  IonInput,
} from "@ionic/vue";
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Analytics } from "../../../common/analytics";
import { useI18n } from "vue-i18n";
import {
  KAKAO_AUTH_SCOPE,
} from "../../../common/config";

export default defineComponent({
  name: "AuthLogin",
  /**
   * components
   */
  components: { IonPage, IonContent, IonInput },
  setup() {
    const router = useRouter();
    const store = useStore();
    const screenName = "PAGE-LOGIN";
    const analytics = new Analytics();
    const { t } = useI18n();
    console.log(store);
    // state
    const state = reactive({
      form: {
        loginid: "",
        pass: "",
      },
    });

    // methods

    const presentAlert = async (message: string) => {
      const alert = await alertController.create({
        header: `${t("notification")}`,
        message: message,
        buttons: [`${t("check")}`],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const kakaoInfo = async (auth: any) => {
      console.log(auth);
      console.log(window);
      console.log((window as any));
      (window as any).Kakao.API.request({
        url: "/v2/user/me",
        success: (res: { kakao_account: { profile: { nickname: string }; email: string; gender: string; age: string; birthday: string; birthyear: string } }) => {
          console.log(res);
          let nickname = "";
          let email = "";
          let gender = "";
          let age = "";
          let birthday = "";
          let birthyear = "";

          if (res.kakao_account) {
            if (res.kakao_account.profile) {
              if (res.kakao_account.profile.nickname) {
                nickname = res.kakao_account.profile.nickname;
              }
            }

            if (res.kakao_account.email) {
              email = res.kakao_account.email;
            }

            if (res.kakao_account.gender) {
              gender = res.kakao_account.gender;
            }

            if (res.kakao_account.age) {
              age = res.kakao_account.age;
            }

            if (res.kakao_account.birthday) {
              birthday = res.kakao_account.birthday;
            }

            if (res.kakao_account.birthyear) {
              birthyear = res.kakao_account.birthyear;
            }
          }

          console.log(res);
          /*
          this.snsLogin({
            type: "kakao",
            id: res.id,
            name: nickname,
            email: email,
            gender: gender,
            age: age,
            birthday: birthday,
            birthyear: birthyear
          });
          */
        },
        fail: (error: any) => {
          console.log(error);
        }
      });
    };

    const login = async (type: string) => {
      if (type == "kakao") {
      console.log(window);
      console.log((window as any).Kakao);
        (window as any).Kakao.Auth.login({
          scope: KAKAO_AUTH_SCOPE,
          success: kakaoInfo
        });
      }
    };

    const onSubmit = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: `${t("screen.auth.login.logging")}`,
      });
      await loading.present();

      store
        .dispatch("login", {
          loginid: state.form.loginid,
          pass: state.form.pass,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            setTimeout(function () {
              loading.dismiss();
              window.location.reload(); // 임시
              /*
                router.push({
                  name: "home"
                })
                */
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((message) => {
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    onMounted(() => {
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    // return
    return {
      t,
      router,
      onSubmit,
      state,
      login,
    };
  },
  mounted() {
    // this.onSubmit();
  },
});
